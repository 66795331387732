const colors = {
  black: "#131415",
  white: "#fff",
  gray: "#fafafa",
  red: "#E74C3C",
  blue: "rgba(80, 161, 252, 1)", //"#5348ff",
  green: "#29B573"
};

export default colors;
